import React from 'react'
import ReactDOM from 'react-dom'
import createReactClass from 'create-react-class'

const SaveForm = createReactClass({
  displayName: 'SaveForm',

  getInitialState() {
    this.playlistTitle = React.createRef();
    return {};
  },

  savePlaylist: function(e) {
    e.preventDefault();
    var title = ReactDOM.findDOMNode(this.playlistTitle.current).value;
    this.props.savePlaylist(title);
  },

  handleEnter: function(e) {
    if (e.keyCode === 13) {
      this.savePlaylist(e);
    }
  },

  render: function() {
    return (
      <div id="save_modal" className="modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal">&times;</button>
              <h4 className="modal-title">Save</h4>
            </div>
            <div className="modal-body">
              <div>
                <input
                  className="form-control"
                  type="text"
                  name="playlist_title_input"
                  ref={this.playlistTitle}
                  onKeyDown={this.handleEnter} 
                  placeholder="Title For Playlist..."
                  data-test="save-form-input-video-link"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
              <a id="playlist_title_yes" className="btn btn-default" href="#" data-test="save-form-save" onClick={this.savePlaylist}>
                <span className="glyphicon glyphicon-floppy-disk"></span> Save
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
});

export default SaveForm
