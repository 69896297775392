import React from 'react'
import createReactClass from 'create-react-class'

// Components
import Tube from './Tube'

const PlaylistTable = createReactClass({
  displayName: 'PlaylistTable',

  render: function() {
    var playlistTubes = this.props.playlist.map(function(tube, i) {
      return (
        <Tube
          key={tube.id}
          title={tube.title}
          videoId={tube.id}
          time={tube.time}
          loadVideo={this.props.loadVideo}
          removeVideo={this.props.removeVideo}
        />
      );
    }.bind(this));
    return (
      <table className="table" id="playlist">
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>Title</th>
            <th>Time</th>
            <th className="remove_column">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {playlistTubes}
        </tbody>
      </table>
    );
  }
});

export default PlaylistTable
