import React from 'react'
import createReactClass from 'create-react-class'

const Flash = createReactClass({
  displayName: 'Flash',

  alertTypes: function(type) {
    var typeMap = {
      "info": "alert-success",
      "notice": "alert-success",
      "warn": "alert-warning",
      "error": "alert-danger"
    };

    return ["alert", typeMap[type]].join(" ");
  },

  clearMessages: function(e) {
    e.preventDefault();

    this.props.clearMessages();
  },

  render: function() {
    var alerts = this.props.flashMessages.map(function(flash, i) {
      var classInfo = this.alertTypes(flash[0]);
      return (
        <div className={classInfo} key={'flash' + i}>
          <p>{flash[1]}<span className="close" onClick={this.clearMessages}>x</span></p>
        </div>
      );
    }.bind(this));

    return (
      <div>
        {alerts}
      </div>
    );
  }
});

export default Flash
