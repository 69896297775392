import React from 'react'
import createReactClass from 'create-react-class'
import moment from 'moment'

const Dash = createReactClass({
  displayName: 'Dash',

  render: function() {
    var recentPlaylists = this.props.recentPlaylists.map(function(list) {
      if (list.title !== '' || list.link_hash !== '') {
        var recentTitle = (list.title !== '') ? list.title : list.link_hash;
        return (
          <tr key={list.link_hash}>
            <td><a href={"/" + list.link_hash}>{recentTitle}</a></td>
            <td className="pull-right">{moment(list.created_at).format("M/DD/YYYY")}</td>
          </tr>
        );
      }
    });
    var popularPlaylists = this.props.popularPlaylists.map(function(list) {
      if (list.hits !== '' || list.title !== '') {
        var popularTitle = (list.title !== '') ? list.title : list.link_hash;
        return (
          <tr key={list.link_hash}>
            <td><a href={"/" + list.link_hash}>{popularTitle}</a></td>
            <td className="pull-right">{list.hits} hits</td>
          </tr>
        );
      }
    });

    return (
      <div id="dash" className="row">
        <div className="col-lg-6">
          <div className="panel panel-default">
            <div className="panel-heading"><h2>Recent</h2></div>
            <table className="table">
              <tbody>
                {recentPlaylists}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="panel panel-default">
            <div className="panel-heading"><h2>Popular</h2></div>
            <table className="table">
              <tbody>
                {popularPlaylists}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
});

export default Dash
