import React from 'react'
import ReactDOM from "react-dom";
import createReactClass from 'create-react-class'
import Utils from './mixins/utils'

const AddTubeLink = createReactClass({
  displayName: 'AddTubeLink',

  mixins: [Utils],

  getInitialState: function() {
    this.pastedLink = React.createRef();
    return {};
  },

  addLink: function(e) {
    e.preventDefault();
    var url = ReactDOM.findDOMNode(this.pastedLink.current).value.trim();
    var videoId = this.extractVideoId(url);
    this.props.addVideo(videoId);
    this.pastedLink.current.value = '';
  },

  componentDidMount: function () {
    this.pastedLink.current.focus();
  },

  handleEnter: function(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.addLink(e);
    }
  },

  render: function() {
    return (
       <div className="row paste-link">
        <div className="col-lg-12">
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                name="input_link"
                ref={this.pastedLink}
                placeholder="Paste Link Here..."
                onKeyDown={this.handleEnter}
                data-test="home-input-video-link"
              />
              <div className="input-group-btn">
                <a className="btn btn-default" onClick={this.addLink}>
                  <span className="glyphicon glyphicon-plus-sign"></span> Add
                </a>
              </div>
            </div>
        </div>
      </div>
    );
  }
});

export default AddTubeLink
