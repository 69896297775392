import React from 'react'
import ReactDOM from 'react-dom'
import createReactClass from 'create-react-class'
import Utils from './mixins/utils'

const AddForm = createReactClass({
  displayName: 'AddForm',

  mixins: [Utils],

  getInitialState() {
    this.pastedLink = React.createRef();
    return {};
  },

  addLink: function(e) {
    e.preventDefault();
    var url = ReactDOM.findDOMNode(this.pastedLink.current).value.trim();
    var videoId = this.extractVideoId(url);
    this.props.addVideo(videoId);
    ReactDOM.findDOMNode(this.pastedLink.current).value = '';

    // TODO: limit query usage
    $("#add_modal").modal("hide");
  },

  handleEnter: function(e) {
    if (e.keyCode === 13) {
      this.addLink(e);
    }
  },

  render: function() {
    return (
      <div id="add_modal" className="modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" data-test="add-form-add">&times;</button>
              <h4 className="modal-title">Add</h4>        
            </div>
            <div className="modal-body">
              <div>
                <input
                  className="form-control"
                  type="text"
                  name="input_link"
                  ref={this.pastedLink}
                  placeholder="Paste Link Here..."
                  onKeyDown={this.handleEnter}
                  data-test="add-form-input-video-link"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
              <a className="btn btn-default" href="#" onClick={this.addLink}>
                <span className="glyphicon glyphicon-plus-sign"></span> Add
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
});

export default AddForm
