import createReactClass from 'create-react-class';
import React from 'react'
import Utils from './mixins/utils'

String.prototype.pad = function(l) {
  var s = this;
  while (s.length < (l || 2)) {s = "0" + s;}
  return s;
};

const Tube = createReactClass({
  displayName: 'Tube',

  mixins: [Utils],

  loadVideo: function(e) {
    e.preventDefault();

    this.props.loadVideo(this.props.videoId);
  },

  removeVideo: function(e) {
    e.preventDefault();

    this.props.removeVideo(this.props.videoId);
  },

  render: function() {

    return (
      <tr id={"row_" + this.props.videoId}>
        <td>
          <a href="#" onClick={this.loadVideo}>
            <span className="glyphicon glyphicon-play-circle"></span>
          </a>
        </td>
        <td>{this.props.title}</td>
        <td style={{textAlign: 'center'}}>{this.formatTime(this.props.time)}</td>
        <td className="remove_column">
          <a href="#" data-test="remove-video" onClick={this.removeVideo}>
            <span className="glyphicon glyphicon-minus-sign"></span>
          </a>
        </td>
      </tr>
    );
  }
});

export default Tube
