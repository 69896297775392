import React from 'react'
import createReactClass from 'create-react-class'

const Nav = createReactClass({
  displayName: 'Nav',

  saveModal: function(e) {
    e.preventDefault();

    // TODO: Try to limit jQuery usage if possible
    $("#save_modal").modal("show");
    $("[name=playlist_title_input]").focus();
  },

  addModal: function(e) {
    e.preventDefault();

    // TODO: Try to limit jQuery usage if possible
    $("#add_modal").modal("show");
    $("[name=input_link]").focus();
  },

  render: function() {
    var actionButton;
    if (this.props.showNavSave) {
      actionButton = (
        <a id="save_list" href="#save_modal" data-test="nav-save" onClick={this.saveModal}>
          <span className="glyphicon glyphicon-floppy-disk"></span> Save
        </a>
      );
    }
    else if (this.props.showNavAdd) {
      actionButton = (
        <a id="modify_list" href="#add_modal" onClick={this.addModal}>
          <span className="glyphicon glyphicon-plus-sign"></span> Add
        </a>
      );
    }
    return (
      <div className="row nav-row">
        <div className="col-lg-12">
          <ul className="nav nav-pills">
            <li><a href={this.props.rootPath}>Home</a></li>
            <li>
              {actionButton}
            </li>
          </ul>
        </div>
      </div>
    );
  }
});

export default Nav
