export default {
  getScreenSize: function(width, height) {
    if ( screen.width < 555 ) {
      width = screen.width - 30;
      height = Math.round( ( width / 16 ) * 9 );
    }
    return { width: width, height: height };
  },

  randomIndex: function(index, setLength) {
    var randSet = [];
    var newRand = Math.floor(Math.random() * (setLength - 1));

    for (var i = 0; i < setLength; i++) {
      if (i != index) {
        randSet.push(i);
      }
    }
    return randSet[newRand];
  },

  extractVideoId: function (url) {
    var match = RegExp("[?&]v=([^&]*)").exec(url);
    if (match) {
      return decodeURIComponent(match[1].replace(/\+/g, " "));
    }
  },

  isMobile: function() {
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
     return true;
    } else {
      return false;
    }
  },

  formatTime: function (seconds) {
    var minutes = Math.floor(seconds / 60);
    var remain = (seconds % 60).toFixed().pad(2);

    return minutes + ":" + remain;
  }
};
