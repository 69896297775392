import React from 'react'
import createReactClass from 'create-react-class'

const PlaylistControls = createReactClass({
  displayName: 'PlaylistControls',

  previousVideo: function(e) {
    e.preventDefault();

    this.props.previousVideo();
  },

  nextVideo: function(e) {
    e.preventDefault();

    this.props.nextVideo();
  },

  startPlaylist: function(e) {
    e.preventDefault();

    this.props.startPlaylist();
  },

  pausePlaylist: function(e) {
    e.preventDefault();

    this.props.pausePlaylist();
  },

  shuffle: function(e) {
    this.props.shuffle(e.target.checked);
  },

  togglePlay: function() {
    if (['NOT_LOADED', 'PAUSED', 'ENDED'].indexOf(this.props.playerState) === -1) {
      return <a id="video_pause" href="#" onClick={this.pausePlaylist}> Pause </a>;
    } else {
      return <a id="video_start" href="#" onClick={this.startPlaylist}> Play </a>;
    }
  },

  render: function() {

    return (
      <form id="player_controls">
        <a href="#" onClick={this.previousVideo} dangerouslySetInnerHTML={{__html: '<< '}}></a>|
        {this.togglePlay()}|
        <a href="#" onClick={this.nextVideo} dangerouslySetInnerHTML={{__html: ' >>'}}></a> <input type="checkbox" name="shuffle" value="shuffle" onChange={this.shuffle} /> Shuffle
      </form>
    );
  }
});

export default PlaylistControls
