import React from 'react'
import createReactClass from 'create-react-class'

// Components
import PlaylistControls from './PlaylistControls'
import PlaylistTable from './PlaylistTable'
import Player from './Player'

const Playlist = createReactClass({
  displayName: 'Playlist',

  render: function() {
    var loadedPlayer;
    if (this.props.playerLoaded) {
      loadedPlayer = (
          <Player
            playerWidth={this.props.playerWidth}
            playerHeight={this.props.playerHeight}
            videoId={this.props.playerVideoId}
            playerStateEnded={this.props.playerStateEnded}
            playerStatePaused={this.props.playerStatePaused}
            playerStatePlaying={this.props.playerStatePlaying}
          />
        );
    } else {
      loadedPlayer = <img src={this.props.playVideoImageUrl} />;
    }

    return (
      <div className="row">
        <div className="col-lg-6 text-center">
          <div id="yt_player" onClick={this.props.startPlaylist}>
            {loadedPlayer}
          </div>
        </div>

        <div className="col-lg-6">
          <div className="row">
            <div className="col-lg-12 text-center">
              <PlaylistControls
                playerState={this.props.playerState}
                startPlaylist={this.props.startPlaylist}
                pausePlaylist={this.props.pausePlaylist}
                nextVideo={this.props.nextVideo}
                previousVideo={this.props.previousVideo}
                shuffle={this.props.shuffle}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
            <PlaylistTable 
              playlist={this.props.playlist}
              loadVideo={this.props.loadVideo}
              removeVideo={this.props.removeVideo}
            />
            </div>
          </div>
        </div>
      </div>
    );
  }
});

export default Playlist
