import React from 'react'
import createReactClass from 'create-react-class'
import update from 'react-addons-update'
import moment from 'moment'
import Utils from './mixins/utils'

// components
import AddTubeLink from './AddTubeLink'
import AddForm from './AddForm'
import Dash from './Dash'
import Flash from './Flash'
import Nav from './Nav'
import Playlist from './Playlist'
import SaveForm from './SaveForm'

const PlayTubes = createReactClass({
  displayName: 'PlayTubes',

  // Player state constants
  playerState: {
    PLAYING: 'PLAYLING',
    PAUSED: 'PAUSED',
    ENDED: 'ENDED',
    NOT_LOADED: 'NOT_LOADED'
  },

  constants: {
    MAX_PLAYLIST_LENGTH: 20,
    MAX_TITLE_LENGTH: 60
  },

  mixins: [Utils],

  getInitialState: function () {
    // Initial playlist
    var initialPlaylist;
    if (this.props.playlist !== 'null') {
      initialPlaylist = JSON.parse(this.props.playlist).tubes.map(function(t) {
        return {
          id: t.tube_id,
          title: t.title,
          time: t.time
        };
      });
    } else {
      initialPlaylist = [];
    }
    var state = {
      playlist: initialPlaylist,
      playerLoaded: false,
      shuffle: false,
      playerWidth: 555,
      playerHeight: 312,
      playerState: this.playerState.NOT_LOADED,
      videoIndex: 0,
      flashMessages: this.props.flashMessages
    };

    return state;
  },

  addVideo: function (videoId) {
    var tube;
    this.getVideoInfo(videoId, function(d) {
      tube = d;
      this.addToPlaylist(tube);
    }.bind(this));
  },

  removeVideo: function(videoId) {
    var removeIndex;
    var newPlaylist = this.state.playlist;

    for(var i = 0; i < newPlaylist.length; i++) {
      if (newPlaylist[i].id === videoId) {
        removeIndex = i;
      }
    }
    newPlaylist.splice(removeIndex, 1);

    this.setState({playlist: newPlaylist});
  },

  getVideoInfo: function(videoId, cb) {
    if (videoId) {
      var dataUrl = "/youtube/video_info";
      var params = "?part=snippet%2CcontentDetails&id=" + videoId + "&callback=?";
      var videoInfo;
      var jqXHR = $.getJSON(dataUrl + params, function (r) {
        if (r && r.items) {
          var videoData = r.items[0];  
          videoInfo = {
              id: videoId,
              title: videoData.snippet.title,
              time: this._parseVideoDuration(videoData.contentDetails.duration)
            };
            cb(videoInfo);
        } else {
          this.showMessage("error", "Invalid request");
        }
      }.bind(this)).fail(function(r) {
        this.showMessage("error", "Invalid request");
      });
    } else {
      this.showMessage("error", "Invalid link.");
    }
  },

  addToPlaylist: function(tube) {
    // check for dupes
    for (var i = 0; i < this.state.playlist.length; i++) {
      if (this.state.playlist[i].id === tube.id) {
        this.showMessage("error", "Already in the list!");
        return;
      }
    }

    if (this.state.playlist.length === this.constants.MAX_PLAYLIST_LENGTH) {
      this.showMessage("error","Reached full capacity!");
      return;
    }

    // TODO: it may be better to save the state off first, instead of modifying state w/o setState
    this.state.playlist.push(tube);
    this.setState({ playlist: this.state.playlist});
  },

  loadPlayer: function(videoId) {
    var playerSize = this.getScreenSize(this.state.playerWidth, this.state.playerHeight);

    // TODO: I'm not sure this is really needed
    var newState = update(this.state, {
      playerLoaded: { $set: true },
      playerHeight: { $set: playerSize.height },
      playerWidth: { $set: playerSize.width }
    });

    // TODO: cheating here with jquery, this might still be the most convenient way to do it
    $('tr').removeClass('highlight');
    $('#row_' + videoId).addClass('highlight');

    this.setState(newState);
  },

  loadVideo: function(videoId) {
    if (this.state.playerState === this.playerState.NOT_LOADED) { 
      this.loadPlayer(videoId);
    } else {
      window.player.loadVideoById(videoId);
    }

    // Reconcile videoIndex
    var newVideoIndex;
    for (var i = 0; i < this.state.playlist.length; i++) {
      if (this.state.playlist[i].id === videoId) {
        newVideoIndex = i;
      }
    }
    this.setState({videoIndex: newVideoIndex});

    // TODO: cheating here with jquery, this might still be the most convenient way to do it
    $('tr').removeClass('highlight');
    $('#row_' + videoId).addClass('highlight');
  },

  startPlaylist: function() {
    if (this.state.playlist.length > 0 && this.state.playerState === this.playerState.NOT_LOADED && this.state.shuffle === false) {
      this.loadPlayer(this.state.playlist[0].id);
    }
    else if (this.state.playlist.length > 0 && this.state.playerState === this.playerState.NOT_LOADED && this.state.shuffle === true) {
      var rand = Math.floor(Math.random() * this.state.playlist.length);
      var newVideoIndex = rand;
      this.loadPlayer(this.state.playlist[newVideoIndex].id);
    } else {
      window.player.playVideo();
    }
  },

  pausePlaylist: function() {
    if (typeof(window.player) != 'undefined') {
      window.player.pauseVideo();
    }
  },

  nextVideo: function() {
    var newVideoIndex = 0;

    if (this.state.shuffle) {
      newVideoIndex = this.randomIndex(this.state.videoIndex, this.state.playlist.length);
    }
    else if (this.state.videoIndex != (this.state.playlist.length - 1)) {
      newVideoIndex = this.state.videoIndex + 1;
    }
    this.loadVideo(this.state.playlist[newVideoIndex].id);
    this.setState({videoIndex: newVideoIndex});
  },

  previousVideo: function() {
    var newVideoIndex = 0;

    if (this.state.shuffle) {
      newVideoIndex = this.randomIndex(this.state.videoIndex, this.state.playlist.length); 
    }
    else if (this.state.videoIndex === 0) {
      newVideoIndex = this.state.playlist.length - 1;
    } else {
      newVideoIndex = this.state.videoIndex - 1;
    }
    this.loadVideo(this.state.playlist[newVideoIndex].id);
    this.setState({videoIndex: newVideoIndex});
  },

  rebuildPlaylistObject: function() {

    // TODO: Try not use jQuery
    var rebuiltList = [];
    var listIds = [];
    $("#playlist tr").each(function(index, element) {
      if (element.id !== "") {
        listIds.push(element.id.substr(4, element.id.length));
      }
    });
    for(var i = 0; i < listIds.length; i++) {
      for(var j = 0; j < this.state.playlist.length; j++) {
        if (this.state.playlist[j].id === listIds[i]) {
          rebuiltList.push(this.state.playlist[j]);
        }
      }
    }

    return rebuiltList;
  },

  setShuffle: function(bool) {
    this.setState({shuffle: bool});
  },

  playerStateEnded: function() {
    var newVideoIndex = 0;

    if (this.state.shuffle) {
      newVideoIndex = randomIndex(this.state.videoIndex, this.playlist.length);
    }
    else if (this.state.videoIndex === (this.state.playlist.length - 1)) {
      newVideoIndex = 0;
    } else {
      newVideoIndex = this.state.videoIndex + 1;
    }

    setTimeout(function() {
      this.loadVideo(this.state.playlist[newVideoIndex].id);
      this.setState({playerState: this.playerState.ENDED, videoIndex: newVideoIndex });
    }.bind(this), 2000);
  },

  playerStatePaused: function() {
    this.setState({playerState: this.playerState.PAUSED });
  },

  playerStatePlaying: function() {
    this.setState({playerState: this.playerState.PLAYING });
  },

  savePlaylist: function(title) {
    if (title.length > this.constants.MAX_TITLE_LENGTH) {
      this.showMessage("error", "Title too long!");
      return;
    }
    else if (title.length < 1) {
      this.showMessage("error", "Title too short!"); 
      return;
    }

    if (this.state.playlist.length > 0) {
      $.post("/save", {
        title: title,
        tubes: this.state.playlist
      }, function(r) {
        if (r.hash) {
          window.location = r.hash;
        }
      }).error(function() {
        this.showMessage("error", "Error saving playlist!");
      });
    } else {
      this.showMessage("warn", "Add videos to the playlist.");
    }
  },

  clearMessages: function() {
    this.setState({flashMessages: []});
  },

  showMessage: function(type, message) {
    this.setState({flashMessages: [[type, message]]});
  },

  _parseVideoDuration: function(duration) {
    return moment.duration(duration)._milliseconds / 1000;
  },

  render: function() {
    var showDash;
    var showPlaylist;
    var showNavSave = false;
    var showNavAdd = false;
    if (this.state.playlist.length > 0) {
      showPlaylist = (
        <Playlist
          playerLoaded={this.state.playerLoaded}
          playerState={this.state.playerState}
          playVideoImageUrl={this.props.playVideoImageUrl}
          playlist={this.state.playlist}
          startPlaylist={this.startPlaylist}
          pausePlaylist={this.pausePlaylist}
          playerHeight={this.state.playerHeight}
          playerWidth={this.state.playerWidth}
          playerVideoId={this.state.playlist[0].id}
          playerStateEnded={this.playerStateEnded}
          playerStatePaused={this.playerStatePaused}
          playerStatePlaying={this.playerStatePlaying}
          nextVideo={this.nextVideo}
          previousVideo={this.previousVideo}
          shuffle={this.setShuffle}
          loadVideo={this.loadVideo}
          removeVideo={this.removeVideo}
        />
      );
      if (!this.props.savedPlaylist) { showNavSave = true; }
    } else {
      showDash = <Dash recentPlaylists={this.props.recentPlaylists} popularPlaylists={this.props.popularPlaylists} />;       
    }

    // Display AddTubeLink component or Title
    var tubeLinkOrTitle;
    if (this.props.savedPlaylist && this.state.playlist.length > 0) {
      tubeLinkOrTitle = (
        <div className="row head">
          <div className="col-lg-12">
            <div id="head_title">
              <img src={this.props.playTubeLogoUrl} width="50" height="50" />
              <h1>{this.props.playlistTitle === "" ? this.props.playlistHash : this.props.playlistTitle}</h1>
            </div>
          </div>
        </div>
      );
    } else {
      tubeLinkOrTitle = <AddTubeLink addVideo={this.addVideo} />;
    }

    return (
      <div>
        {tubeLinkOrTitle}
        <Nav rootPath={this.props.rootPath} showNavSave={showNavSave} showNavAdd={this.props.savedPlaylist ? true : false} />
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-12">
                <Flash
                  flashMessages={this.state.flashMessages}
                  clearMessages={this.clearMessages}
                />
              </div>
            </div>
            <div className="row dash-row">
              <div className="col-lg-12">
                {showDash}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                {showPlaylist}
              </div>
            </div>
          </div>
        </div>
        <SaveForm savePlaylist={this.savePlaylist} />
        <AddForm addVideo={this.addVideo} />
      </div>
    );
  }
});

export default PlayTubes
