import React from 'react'
import createReactClass from 'create-react-class'
import Utils from './mixins/utils'

const Player = createReactClass({
  displayName: 'Player',

  mixins: [Utils],

  componentDidMount: function() {
    if (window.player !== undefined) {
      var ytJS = document.createElement('script');
      ytJS.src = "//www.youtube.com/iframe_api";
      var firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(ytJS, firstScriptTag);

      window.onYouTubeIframeAPIReady = function() {
        window.player = new YT.Player("player", {
          videoId: this.props.videoId,
          width: this.props.playerWidth,
          height: this.props.playerHeight,
          playerVars: {
            iv_load_policy: 3,
            modestbranding: 1,
            rel: 0
          },
          events: {
            onReady: onPlayerReady,
            onStateChange: onPlayerStateChange
          }
        });
      }.bind(this);

      window.onPlayerReady = function(e) {
        if (!this.isMobile()) {
          e.target.playVideo();
        }
      }.bind(this);
    }

    window.onPlayerStateChange = function(e) {
      if (e.data === YT.PlayerState.ENDED) {
        this.props.playerStateEnded();
      }
      else if (e.data === YT.PlayerState.PLAYING) {
        this.props.playerStatePlaying();
      }
      else if (e.data === YT.PlayerState.PAUSED) {
        this.props.playerStatePaused();
      }
    }.bind(this);
  },

  render: function() {
    return (
      <div id="player"></div>
    );
  }
});

export default Player
